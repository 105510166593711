import { SelectPatientLabel } from '../../parts/AppointDetail';
import { SearchPatientModal } from '@/feature/phr-28/components/template/Appointment/ModalContents/SearchPatientModal';

export interface SelectPatientProps {
  disabled: boolean;
  displayPatientName: string;
  watch: any;
  setOpenSearchPatientModal: any;
  openSearchPatientModal: boolean;
  setOpenAddPatientModal: any;
}

export function SelectPatient({
  disabled,
  displayPatientName,
  watch,
  setOpenSearchPatientModal,
  openSearchPatientModal,
  setOpenAddPatientModal,
}: SelectPatientProps): React.ReactElement {
  return (
    <>
      <SelectPatientLabel
        displayPatientName={displayPatientName}
        disabled={disabled}
        // 患者未選択時にハイライト
        isHighlighted={
          watch('appointData.id').startsWith('UN-') || watch('appointData.id') === '0'
        }
        openSearchPatientModal={() => {
          setOpenSearchPatientModal(true);
        }}
      />
      {openSearchPatientModal && (
        <SearchPatientModal
          setOpenAddPatientModal={setOpenAddPatientModal}
          setOpenSearchPatientModal={setOpenSearchPatientModal}
        />
      )}
    </>
  );
}
